import React from 'react'
import {Layout} from 'layouts'
import {graphql, Link, useStaticQuery} from 'gatsby'
import {Trans, useTranslation} from 'react-i18next'
import iDashboard from 'assets/images/illustrations-dashboard.svg'

function Page() {
  const {t} = useTranslation()
  const {
    allOffers: {nodes},
  } = useStaticQuery(graphql`
    {
      allOffers {
        nodes {
          meta {
            annual_price_ratio
            default_plan
            count
          }
          items {
            offer_id
            offer_name
            descriptions
            settings {
              trademarks_count
              user_limit
              keywords_count
              frequency
            }
            prices {
              offer_price_id
              frequency_type_cd
              price
              currency_type_cd
              total_price
              frequency_type_cd_localized
            }
          }
        }
      }
    }
  `)
  // !IMPORTANT
  const {
    items: list,
    meta: {annual_price_ratio: ratio},
  } = nodes[0].meta ? nodes[0] : nodes[1]

  const annuallyDiscount = price =>
    Math.ceil(price * (ratio || 1))

  return (
    <Layout
      title={t('Pricing - Save 10%')}
      description={t(
        'The Most Affordable Solution for Brand Monitoring on Search Engines'
      )}
      className="bg-[url(assets/images/bg-pricing.svg)] bg-contain bg-top bg-no-repeat relative"
    >
      <img
        alt="dashboard"
        src={iDashboard}
        className="absolute right-0 top-[240px] xl:block hidden"
      />
      <section className="flex flex-col items-center text-center text-gray-900 relative">
        <h1 className="font-head font-extrabold md:text-5xl text-2xl my-10">
          <Trans>{t('BEST PLANS FOR<br/>BUSINESS')}</Trans>
        </h1>
        <p className="md:text-3xl text-lg px-5 md:my-10 max-w-5xl">
          <Trans>
            The Most Affordable Solution for Brand Monitoring on
            Search Engines
          </Trans>
        </p>
        <p
          className="font-semibold tracking-wide uppercase max-w-5xl
        md:text-base text-xs md:mt-0 mt-6 px-5"
        >
          <Trans>
            {t(
              'Protect your Branded terms with an Automated Monitoring Tool.'
            )}
          </Trans>
        </p>
        <div
          className="my-10 md:mx-0 mx-4 rounded-md p-1 grid grid-cols-2 bg-gray-200
        md:text-base text-sm"
        >
          <Link
            to="/pricing"
            className="md:px-10 md:py-2 rounded-md bg-gray-200
             flex items-center justify-center"
          >
            Monthly Billing
          </Link>
          <Link
            to="/pricing/annually"
            className="md:px-10 md:py-2 p-1 rounded-md bg-violet-700
            text-white leading-snug
            flex md:flex-col flex-row items-center justify-center"
          >
            {' '}
            Annually Billing
            <span className="ml-2 text-green-500">
              Save {100 - annuallyDiscount(100)}%
            </span>
          </Link>
        </div>
        <a
          href=""
          className="font-semibold text-center uppercase text-green-500
          px-8"
        >
          {t('Sign up for a 3-day free trial now.')}
        </a>
        <div className="grid md:grid-cols-2 my-8">
          {list.map(item => (
            <div
              key={item.offer_name}
              className="rounded-3xl bg-white border-8 border-stone-50
            shadow-xl p-6 md:m-2 m-4"
            >
              <div className="text-left max-w-xs">
                <span className="text-base font-semibold text-left text-stone-300">
                  PLAN
                </span>
                <h3 className="text-3xl text-left text-gray-900 uppercase">
                  {item.offer_name}
                </h3>
                <p className="text-base text-left text-gray-500 my-4">
                  {item.offer_description}
                </p>
                <h4 className="flex items-center my-4">
                  <span className="font-head text-5xl text-gray-900 mr-2">
                    ${annuallyDiscount(item.prices[0].price)}
                  </span>
                  {/* ${item.prices[0].price} */}
                  <span className="flex flex-col uppercase">
                    <span className="font-semibold text-stone-300">
                      / {t('Monthly')}
                    </span>
                    <span className="text-xs text-gray-500">
                      {t('Cancel any time')}
                    </span>
                  </span>
                </h4>

                <a
                  className="block w-full flex justify-center items-center py-3 rounded-md bg-green-500 shadow
                  font-semibold text-white my-8"
                  href={`${process.env.GATSBY_AUTH}/register/guard?plan_name=${item.offer_name}&plan=${item.offer_id}`}
                >
                  {t('SIGN UP FOR FREE')}
                </a>

                <ul className="text-sm text-left text-gray-500">
                  {item.descriptions?.map((li, i) => (
                    <li
                      key={'li' + i}
                      className="flex items-start mb-4"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mr-3"
                      >
                        <path
                          d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                          fill="#10B981"
                        />
                      </svg>

                      {li}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default Page
